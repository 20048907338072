import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/HomePage.vue'
import Map from '@/components/MapPage.vue'
import Resources from '@/components/ResourcesPage.vue'
import About from '@/components/AboutPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
