<template>
    <div>
      <b-container class="py-4">
        <b-row :rows="3">
          <b-col v-for="item in items" :key="item.id" class="mb-4" cols="4">
            <b-card class="h-100 cell-background cell-text">
              <b-img :src="item.image" alt="image" class="mb-3" height="150px" object-fit="cover"></b-img>
              <b-card-title class="mb-0">{{ item.title }}</b-card-title>
              <b-card-text class="text-muted mt-2">{{ item.description }}</b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          {
            title: 'Mishti Gokhale',
            description: 'PUBLIC HEALTH POLICY',
            image: require('../assets/mishti.png')
          },
          {
            title: 'Zachary Romero',
            description: 'INFORMATICS',
            image: require('../assets/zach.jpg')
          },
          {
            title: 'Selena Nguyen',
            description: 'BIOLOGICAL SCIENCES',
            image: require('../assets/selena.jpeg')
          },
          {
            title: 'Kathy Bui',
            description: 'PUBLIC HEALTH SCIENCE',
            image: require('../assets/kathy.png')
          },
          {
            title: 'Fawaz Al-Harabi',
            description: 'INFORMATICS',
            image: require('../assets/fawaz.png')
          },
          {
            title: 'Abigail Bojorquez',
            description: 'PSYCHOLOGICAL SCIENCE',
            image: require('../assets/abby.jpg')
          },
          {
            title: 'Suhong He',
            description: 'PUBLIC HEALTH POLICY',
            image: require('../assets/suhong.jpg')
          },
          {
            title: 'Yuanying Zhong',
            description: 'INFORMATICS',
            image: require('../assets/yuanying.jpeg')
          },
          {
            title: 'William Ku',
            description: 'BUSINESS INFORMATION MANAGEMENT',
            image: require('../assets/william.jpg')
          }
        ]
      }
    }
  }
  </script>
  
<style>
.cell-background {
  background-color: #F9AA33;
}

.cell-text {
  color: #353535;
}

.cell-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.cell-description {
  font-size: 0.9rem;
  color: #f2f2f2 
}
  </style>