<template>
  <div class="resources">
    <b-container>
      <h2 class="title">Find information about health centers near you:</h2>
      <div class="dropdowns">
        <b-dropdown id="stateSelect" v-model="selectedState" v-bind:text="(selectedState == '' ? 'Select a state' : selectedState)">
          <b-dropdown-item v-bind:key="s" v-for="s in UsStates" @click="setState(s)">{{ s }}</b-dropdown-item>
        </b-dropdown>
        
        <b-dropdown id="citySelect" v-model="selectedCity" v-bind:text="(selectedCity == '' ? 'Select a city' : selectedCity)">
          <b-dropdown-item v-bind:key="c.field1" v-for="c in cities" @click="displayResources(c.City)">{{ c.City }}</b-dropdown-item>
        </b-dropdown>
      </div>
      <b-table striped hover v-if="finalCenters" :items="finalCenters" :fields="fields">
        <template v-slot:cell(Url)="data">
          <a :href="data.value" target="_blank">{{ data.value }}</a>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<style>

body {
  background-color: #4A6572;
}
.result-table {
  width: 50%;
  text-align: center;
}
.download-btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 12px 30px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 20px;  
}
/* Darker background on mouse-over */
.download-btn:hover {
  background-color: RoyalBlue;
}

#stateSelect /deep/ .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}

#citySelect {
  margin-left: 20px;
}

#citySelect /deep/ .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}

.dropdowns {
  margin: 50px;
}

.title {
  margin-top: 50px;
}
</style>

<script>
/* eslint-disable no-unused-vars */

export default {
    name: 'ResourcesPage',
    data() {
      return {
        selectedCity: '',
        selectedState: '',
        healthCenters: [],
        finalCenters: null,
        cities: [],
        fields: ['Hospital', 'Url'],
        UsStates: ['Alabama',
                    'Alaska',
                    'Arizona',
                    'Arkansas',
                    'California',
                    'Colorado',
                    'Connecticut',
                    'Delaware',
                    'Florida',
                    'Georgia',
                    'Hawaii',
                    'Idaho',
                    'Illinois',
                    'Indiana',
                    'Iowa',
                    'Kansas',
                    'Kentucky',
                    'Louisiana',
                    'Maine',
                    'Maryland',
                    'Massachusetts',
                    'Michigan',
                    'Minnesota',
                    'Mississippi',
                    'Missouri',
                    'Montana',
                    'Nebraska',
                    'Nevada',
                    'New Hampshire',
                    'New Jersey',
                    'New Mexico',
                    'New York',
                    'North Carolina',
                    'North Dakota',
                    'Ohio',
                    'Oklahoma',
                    'Oregon',
                    'Pennsylvania',
                    'Rhode Island',
                    'South Carolina',
                    'South Dakota',
                    'Tennessee',
                    'Texas',
                    'Utah',
                    'Vermont',
                    'Virginia',
                    'Washington',
                    'West Virginia',
                    'Wisconsin',
                    'Wyoming']
      }
    },
    computed: {
    },
    methods: {
      async setState(state) {
        this.healthCenters = import("../data/hospitals/" + state + ".json").then(result => {
          return Object.values(result);
        }).then(res => {
          this.selectedState = state;
          
          var output = [];
          var keys = [];
          for (let i = 0; i < res.length; i++) {
            var center = res[i]
            var key = center['City'];

            if (keys.indexOf(key) === -1) {
                keys.push(key);
                output.push(center);
            }
          }
          this.cities = output;
          return res;
        });
      },
      async displayResources(city) {
        this.selectedCity = city;
        this.finalCenters = await this.healthCenters;
        this.finalCenters = this.finalCenters.filter(center => center.City == city);
      }
    }
}
</script>
