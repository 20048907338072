export var usCountyData = [
 {
  "county":"Ada",
  "low_risk":3,
  "high_risk":1
 },
 {
  "county":"Alachua",
  "low_risk":5,
  "high_risk":1
 },
 {
  "county":"Alameda",
  "low_risk":35,
  "high_risk":4
 },
 {
  "county":"Albany",
  "low_risk":1,
  "high_risk":2
 },
 {
  "county":"Alexandria",
  "low_risk":9,
  "high_risk":3
 },
 {
  "county":"Allegheny",
  "low_risk":11,
  "high_risk":3
 },
 {
  "county":"Anne Arundel",
  "low_risk":2,
  "high_risk":2
 },
 {
  "county":"Arlington",
  "low_risk":15,
  "high_risk":2
 },
 {
  "county":"Baltimore",
  "low_risk":26,
  "high_risk":9
 },
 {
  "county":"Benton",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Bernalillo",
  "low_risk":22,
  "high_risk":6
 },
 {
  "county":"Bexar",
  "low_risk":51,
  "high_risk":6
 },
 {
  "county":"Bibb",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Boulder",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Brazos",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Bronx",
  "low_risk":47,
  "high_risk":6
 },
 {
  "county":"Broome",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Broward",
  "low_risk":12,
  "high_risk":4
 },
 {
  "county":"Cache",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Camden",
  "low_risk":3,
  "high_risk":1
 },
 {
  "county":"Cameron",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Charlottesville",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Cherokee",
  "low_risk":1,
  "high_risk":2
 },
 {
  "county":"Clark",
  "low_risk":85,
  "high_risk":12
 },
 {
  "county":"Clarke",
  "low_risk":3,
  "high_risk":1
 },
 {
  "county":"Clay",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Cleburne",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Cleveland",
  "low_risk":9,
  "high_risk":1
 },
 {
  "county":"Collin",
  "low_risk":13,
  "high_risk":3
 },
 {
  "county":"Cook",
  "low_risk":165,
  "high_risk":15
 },
 {
  "county":"Coweta",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Cumberland",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Cuyahoga",
  "low_risk":20,
  "high_risk":3
 },
 {
  "county":"Dallas",
  "low_risk":71,
  "high_risk":9
 },
 {
  "county":"Davidson",
  "low_risk":33,
  "high_risk":11
 },
 {
  "county":"DeKalb",
  "low_risk":9,
  "high_risk":5
 },
 {
  "county":"Denton",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Denver",
  "low_risk":56,
  "high_risk":8
 },
 {
  "county":"District of Columbia",
  "low_risk":61,
  "high_risk":8
 },
 {
  "county":"Douglas",
  "low_risk":36,
  "high_risk":6
 },
 {
  "county":"DuPage",
  "low_risk":4,
  "high_risk":3
 },
 {
  "county":"Dubuque",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Durham",
  "low_risk":14,
  "high_risk":1
 },
 {
  "county":"East Baton Rouge",
  "low_risk":11,
  "high_risk":3
 },
 {
  "county":"El Paso",
  "low_risk":18,
  "high_risk":4
 },
 {
  "county":"Elkhart",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Erie",
  "low_risk":24,
  "high_risk":2
 },
 {
  "county":"Escambia",
  "low_risk":5,
  "high_risk":2
 },
 {
  "county":"Essex",
  "low_risk":6,
  "high_risk":2
 },
 {
  "county":"Fairfax",
  "low_risk":10,
  "high_risk":8
 },
 {
  "county":"Fairfield",
  "low_risk":10,
  "high_risk":2
 },
 {
  "county":"Forsyth",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Fort Bend",
  "low_risk":3,
  "high_risk":1
 },
 {
  "county":"Franklin",
  "low_risk":198,
  "high_risk":9
 },
 {
  "county":"Frio",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Fulton",
  "low_risk":84,
  "high_risk":16
 },
 {
  "county":"Gaston",
  "low_risk":1,
  "high_risk":2
 },
 {
  "county":"Geary",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Greene",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Gregg",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Guilford",
  "low_risk":11,
  "high_risk":2
 },
 {
  "county":"Hamilton",
  "low_risk":31,
  "high_risk":4
 },
 {
  "county":"Hampton",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Harris",
  "low_risk":128,
  "high_risk":20
 },
 {
  "county":"Hartford",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Hendricks",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Hennepin",
  "low_risk":31,
  "high_risk":5
 },
 {
  "county":"Henry",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Hidalgo",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Hillsborough",
  "low_risk":33,
  "high_risk":3
 },
 {
  "county":"Houston",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Hudson",
  "low_risk":9,
  "high_risk":2
 },
 {
  "county":"Ingham",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Iredell",
  "low_risk":5,
  "high_risk":1
 },
 {
  "county":"Jackson",
  "low_risk":25,
  "high_risk":6
 },
 {
  "county":"Jefferson",
  "low_risk":40,
  "high_risk":9
 },
 {
  "county":"Johnson",
  "low_risk":13,
  "high_risk":11
 },
 {
  "county":"Johnston",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Kent",
  "low_risk":10,
  "high_risk":1
 },
 {
  "county":"Kern",
  "low_risk":7,
  "high_risk":1
 },
 {
  "county":"King",
  "low_risk":56,
  "high_risk":4
 },
 {
  "county":"Kings",
  "low_risk":104,
  "high_risk":13
 },
 {
  "county":"Knox",
  "low_risk":5,
  "high_risk":1
 },
 {
  "county":"Lafayette",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Lancaster",
  "low_risk":15,
  "high_risk":1
 },
 {
  "county":"Lane",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Lee",
  "low_risk":3,
  "high_risk":3
 },
 {
  "county":"Lehigh",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Lenawee",
  "low_risk":1,
  "high_risk":2
 },
 {
  "county":"Leon",
  "low_risk":5,
  "high_risk":2
 },
 {
  "county":"Linn",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Lorain",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Los Angeles",
  "low_risk":414,
  "high_risk":42
 },
 {
  "county":"Lubbock",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Lucas",
  "low_risk":9,
  "high_risk":2
 },
 {
  "county":"Macomb",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Madison",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Maricopa",
  "low_risk":125,
  "high_risk":23
 },
 {
  "county":"Marin",
  "low_risk":3,
  "high_risk":2
 },
 {
  "county":"Marion",
  "low_risk":39,
  "high_risk":5
 },
 {
  "county":"McLennan",
  "low_risk":5,
  "high_risk":1
 },
 {
  "county":"Mecklenburg",
  "low_risk":46,
  "high_risk":8
 },
 {
  "county":"Miami-Dade",
  "low_risk":33,
  "high_risk":11
 },
 {
  "county":"Middlesex",
  "low_risk":9,
  "high_risk":4
 },
 {
  "county":"Milwaukee",
  "low_risk":21,
  "high_risk":3
 },
 {
  "county":"Mobile",
  "low_risk":9,
  "high_risk":1
 },
 {
  "county":"Monmouth",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Monongalia",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Monterey",
  "low_risk":4,
  "high_risk":3
 },
 {
  "county":"Montgomery",
  "low_risk":4,
  "high_risk":2
 },
 {
  "county":"Multnomah",
  "low_risk":1399,
  "high_risk":1
 },
 {
  "county":"Nevada",
  "low_risk":5,
  "high_risk":2
 },
 {
  "county":"New Castle",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"New Haven",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"New York",
  "low_risk":94,
  "high_risk":28
 },
 {
  "county":"Newton",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Niagara",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Norfolk",
  "low_risk":17,
  "high_risk":1
 },
 {
  "county":"Nueces",
  "low_risk":10,
  "high_risk":1
 },
 {
  "county":"Oakland",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Oklahoma",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Onondaga",
  "low_risk":7,
  "high_risk":3
 },
 {
  "county":"Orange",
  "low_risk":72,
  "high_risk":18
 },
 {
  "county":"Orleans",
  "low_risk":37,
  "high_risk":2
 },
 {
  "county":"Osceola",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Palm Beach",
  "low_risk":12,
  "high_risk":2
 },
 {
  "county":"Passaic",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Philadelphia",
  "low_risk":75,
  "high_risk":13
 },
 {
  "county":"Pierce",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Pima",
  "low_risk":21,
  "high_risk":6
 },
 {
  "county":"Pinellas",
  "low_risk":9,
  "high_risk":4
 },
 {
  "county":"Platte",
  "low_risk":6,
  "high_risk":1
 },
 {
  "county":"Polk",
  "low_risk":8,
  "high_risk":3
 },
 {
  "county":"Portsmouth",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Prince George's",
  "low_risk":4,
  "high_risk":2
 },
 {
  "county":"Prince William",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Providence",
  "low_risk":14,
  "high_risk":1
 },
 {
  "county":"Pulaski",
  "low_risk":196,
  "high_risk":1
 },
 {
  "county":"Queens",
  "low_risk":57,
  "high_risk":11
 },
 {
  "county":"Racine",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Rensselaer",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Richmond",
  "low_risk":24,
  "high_risk":7
 },
 {
  "county":"Riley",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Robertson",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Sacramento",
  "low_risk":27,
  "high_risk":3
 },
 {
  "county":"Salt Lake",
  "low_risk":18,
  "high_risk":2
 },
 {
  "county":"San Bernardino",
  "low_risk":17,
  "high_risk":3
 },
 {
  "county":"San Diego",
  "low_risk":81,
  "high_risk":19
 },
 {
  "county":"San Francisco",
  "low_risk":94,
  "high_risk":8
 },
 {
  "county":"San Joaquin",
  "low_risk":8,
  "high_risk":3
 },
 {
  "county":"San Luis Obispo",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"San Mateo",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Santa Barbara",
  "low_risk":5,
  "high_risk":1
 },
 {
  "county":"Santa Clara",
  "low_risk":25,
  "high_risk":3
 },
 {
  "county":"Santa Cruz",
  "low_risk":8,
  "high_risk":3
 },
 {
  "county":"Scott",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Sedgwick",
  "low_risk":8,
  "high_risk":2
 },
 {
  "county":"Shasta",
  "low_risk":3,
  "high_risk":1
 },
 {
  "county":"Shelby",
  "low_risk":22,
  "high_risk":6
 },
 {
  "county":"Somerset",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Sonoma",
  "low_risk":7,
  "high_risk":1
 },
 {
  "county":"Spokane",
  "low_risk":5,
  "high_risk":2
 },
 {
  "county":"St. Joseph",
  "low_risk":2,
  "high_risk":2
 },
 {
  "county":"St. Louis",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"St. Tammany",
  "low_risk":1,
  "high_risk":2
 },
 {
  "county":"Suffolk",
  "low_risk":31,
  "high_risk":2
 },
 {
  "county":"Tallapoosa",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Tarrant",
  "low_risk":39,
  "high_risk":11
 },
 {
  "county":"Tompkins",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Travis",
  "low_risk":59,
  "high_risk":8
 },
 {
  "county":"Trumbull",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Tulsa",
  "low_risk":11,
  "high_risk":4
 },
 {
  "county":"Tuscaloosa",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Ventura",
  "low_risk":7,
  "high_risk":3
 },
 {
  "county":"Wake",
  "low_risk":24,
  "high_risk":4
 },
 {
  "county":"Walker",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Waller",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Washington",
  "low_risk":4,
  "high_risk":1
 },
 {
  "county":"Washoe",
  "low_risk":10,
  "high_risk":4
 },
 {
  "county":"Wayne",
  "low_risk":30,
  "high_risk":4
 },
 {
  "county":"Westchester",
  "low_risk":3,
  "high_risk":2
 },
 {
  "county":"Whatcom",
  "low_risk":2,
  "high_risk":1
 },
 {
  "county":"Will",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Wilson",
  "low_risk":1,
  "high_risk":1
 },
 {
  "county":"Worcester",
  "low_risk":9,
  "high_risk":1
 },
 {
  "county":"York",
  "low_risk":2,
  "high_risk":1
 }
]