<template>
    <div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <div class="map-selectors">
        <input type="radio" id="map1" value="map1" v-model="selectedMap" name="map-selectors">
        <label for="map1">Low Risk</label>
        <input type="radio" id="map2" value="map2" v-model="selectedMap" name="map-selectors">
        <label for="map2">High Risk</label>
      </div>
      <div v-if="selectedMap === 'map1'" id="app" style="text-align: left;">
        <l-map :center="[37.0902, -95.7129]" :zoom="5" style="height: 700px;" :options="mapOptions">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-choropleth-layer :data="usCountyData" titleKey="county" idKey="county" :value="lowRiskValue" :extraValues="lowRiskExtraValues" geojsonIdKey="NAME" :geojson="usCountyGeojson" :colorScale="colorScale">
            <template slot-scope="props">
              <div class="info-control-wrapper">
                <l-info-control :item="props.currentItem" :unit="props.unit" title="County" placeholder="Hover over a county"/>
              </div>
              <l-reference-chart title="Estimated Cases by Tweets" :colorScale="colorScale" :min="props.min" :max="props.max" position="topright"/>
            </template>
          </l-choropleth-layer>
        </l-map>
      </div>
      <div>
        <div v-if="selectedMap === 'map2'" id="app" style="text-align: left;">
          <l-map :center="[37.0902, -95.7129]" :zoom="5" style="height: 700px;" :options="mapOptions">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-choropleth-layer :data="usCountyData" titleKey="county" idKey="county" :value="highRiskValue" :extraValues="highRiskExtraValues" geojsonIdKey="NAME" :geojson="usCountyGeojson" :colorScale="colorScale">
              <template slot-scope="props">
                <div class="info-control-wrapper">
                  <l-info-control :item="props.currentItem" :unit="props.unit" title="County" placeholder="Hover over a county"/>
                </div>
                <l-reference-chart title="Estimated Cases by Tweets" :colorScale="colorScale" :min="props.min" :max="props.max" position="topright"/>
              </template>
            </l-choropleth-layer>
          </l-map>
        </div>
      </div>
      <div class ="education">
        <h1>Educational Information</h1>
        <table class="info-container">
          <tbody class="info-body">
            <tr class="video-row">
              <td>
                <iframe width="450" height="315" src="https://www.youtube.com/embed/2PZLc6Ys1fQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </td>
              <td>
                <iframe width="450" height="315" src="https://www.youtube.com/embed/pbsYg6nyMzQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </td>
              <td>
                <iframe width="450" height="315" src="https://www.youtube.com/embed/yWZC2ol2bK0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </td>
            </tr>
            <tr class="info-row">
              <td class="cold-flu-container">
                <div class="cold-flu-info">
                  <h2 class="titles">Flu & Cold Symptoms</h2>
                  <ul>
                    <li>Cough</li>
                    <li>Runny/Stuffy Nose</li>
                    <li>Sore Throat</li>
                    <li>Body Aches</li>
                    <li>Headaches</li>
                    <li>Fatigue</li>
                    <li>Fever</li>
                  </ul>
                </div>
              </td>
              <td class="pneu-bronc-container">
                <div class="pneu-bronc-info">
                    <h2 class="titles">Pneumonia and Bronchitis Symptoms</h2>
                    <ul>
                      <li>Difficulty Breathing</li>
                      <li>Cough/Production of Mucus (clear, white, yellow-gray, or green)</li>
                      <li>Rapid Heartbeat</li>
                      <li>Sweating/Chills</li>
                      <li>Chest Pain</li>
                      <li>Fatigue</li>
                      <li>Fever</li>
                    </ul>
                  </div>
              </td>
              <td class="doctor-container">
                <div class="doctor-info">
                  <h2 class="titles">When Should You See a Doctor?</h2>
                  <h3 class="titles">For Flu/Cold</h3>
                  <ul>
                    <li>Fever Above 101 Fahrenheit Lasting More than Two Days </li>
                    <li>Symptoms Failing to Improve</li>
                    <li>Wheezing</li>
                    <li>Severe Sore Throat, Headache, or Sinus Pain</li>
                  </ul>
                  <h3 class="titles">For Pneumonia/Bronchitis</h3>
                  <ul>
                    <li>Coughing with Clear, Yellow Sputum</li>
                    <li>Discomfort in the Center of the Chest</li>
                    <li>Wheezing</li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="factors">
      <div>
        <span class="title special">What Are the Risk Factors?</span>
        <span class="Subtitle">1.People who Smoking</span>
        <span class="text">There are 75% of people who have chronic bronchitis smoke or used to smoke</span>
        <span class="Subtitle">2.Age</span>
        <span class="text">As people get older, the immune system becomes less effective at fighting off infections. This
          can make
          people more susceptible to</span>
        <span class="text">respiratory infections like influenza, pneumonia, and flu.</span>
        <span class="Subtitle">3.Long-term exposure to lung irritants</span>
        <span class="text">Including secondhand smoke, air pollution, dusts from the environment or workplace</span>
        <span class="Subtitle">4. Environmental exposure</span>
        <span class="text">Exposure to environmental pollutants like smoke,dust, and chemicals can damage the lungs and
          increase the
          risk of respiratory</span>
        <span class="text">disease.</span>
        <i class="line"></i>
      </div>
    </div>
    <div class="action">
      <div class="action2">
        <span class="title special">What Are the Risk Factors?</span>
        <span class="Subtitle Underline">Get vaccinated!</span>
        <span class="text">- Getting vaccinate can help prepare your body to combat flu and cold symptoms and stop them
          from progressing to more severe</span>
        <span class="text">respiratory disease conditions</span>
        <span class="Subtitle Underline">Practice Healthy Habit</span>
        <span class="text">- Get plenty of sleep, be physically active, manage your stress, drink plenty of water, and eat
          nutritious foods</span>
        <i class="line"></i>
      </div>
    </div>
    <div class="action">
      <div>
        <span class="title special">Frequently Asked Questions</span>
        <span class="Subtitle">1.What are the most vulnerable population?</span>
        <span class="text">- Infant and young adults</span>
        <span class="text">- Adult over 65</span>
        <span class="Subtitle">2.Who should I consult when I experience the symptoms?</span>
        <span class="text">- Primary care doctors</span>
        <i class="line"></i>
      </div>
    </div>
    <div class="fixed">
      <svg viewBox="64 64 896 896" focusable="false" fill="#ffffff" width="50" height="50" data-icon="question-circle"
        aria-hidden="true">
        <path
          d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z">
        </path>
      </svg>
    </div>
    </div>
  </template>
  
<script>
import { InfoControl, ReferenceChart, ChoroplethLayer } from 'vue-choropleth'

//import { geojson } from './data/py-departments-geojson'
import usCountyGeojson from '../data/us_counties_simplified.json'
import { usCountyData } from '../data/low_high_counts_county'
import { LMap, LTileLayer } from 'vue2-leaflet';

export default {
  name: "app",
  components: {
    LMap,
    LTileLayer,
    'l-info-control': InfoControl,
    'l-reference-chart': ReferenceChart,
    'l-choropleth-layer': ChoroplethLayer
  },
  created() {
    document.title = "Choropleth Map";
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      usCountyData,
      usCountyGeojson,
      selectedMap: 'map1',
      colorScale: ["e7d090", "e9ae7b", "de7062"],
      lowRiskValue: {
        key: "low_risk",
        metric: " Estimated Cases of Cold/Flu"
      },
      lowRiskExtraValues: [
        {
          key: "high_risk",
          metric: " Estimated Cases of Pneumonia/Bronchitis"
        }
      ],
      highRiskValue: {
        key: "high_risk",
        metric: " Estimated Cases of Pneumonia/Bronchitis"
      },
      highRiskExtraValues: [
        {
          key: "low_risk",
          metric: " Estimated Cases of Cold/Flu"
        }
      ],
      mapOptions: {
        attributionControl: false
      },
      currentStrokeColor: '3d3213'
    }
  },
}
</script>
<style>
body {
  background-color: #1dafc2;
  margin-left: 0px;
  margin-right: 0px;
}

ul {
  list-style-type: none;
  padding-left: 0;
  color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.titles {
  color: white;
}

tr,
td {
  text-align: center;
  vertical-align: top;
  padding: 5px;
}

.info-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cold-flu-container {
  text-align: left;
}

.cold-flu-info {
  text-align: center;
}

.pneu-bronc-container {
  text-align: center;
}

.pneu-bronc-info {
  text-align: center;
}

.doctor-container {
  text-align: right;
}

.doctor-info {
  text-align: center;
}

#map {
  background-color: rgb(0, 0, 0);
}

.factors,
.action {
  width: 100%;
}

.factors>div,
.action>div {
  display: flex;
  padding: 55px;
  padding-left: 125px;
  background-color: rgb(220, 220, 220);
  flex-direction: column;
  align-items: flex-start;
}

.action2 {
  background-color: rgb(241, 241, 241) !important;

}

.display>div {
  padding-top: unset !important;
}

  .map-selectors {
    background-color: #232F34;
    font-weight: bold;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #e2e6eb;
  }
  
  h1,h2 {
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #e2e6eb;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .map {
    background-color: rgb(0, 0, 0);
  }
  
  .factors,
.action {
  width: 100%;
}

.factors>div,
.action>div {
  display: flex;
  padding: 55px;
  padding-left: 125px;
  background-color: rgb(220, 220, 220);
  flex-direction: column;
  align-items: flex-start;
}

.action2 {
  background-color: rgb(241, 241, 241) !important;

}

.display>div {
  padding-top: unset !important;
}

.title {
  font-size: 30px;
  font-weight: bold;
  color: black;
}

.Subtitle {
  color: rgb(63, 63, 63);
  font-size: 20px;
  font-weight: 700;
}

.text {
  font-weight: 500;
  line-height: 1.5;
}

.special {
  padding-bottom: 25px;
}

.Underline {
  text-decoration: underline
}

.line {
  width: 100%;
  height: 1px;
  display: inline-block;
  background-color: black;
  margin-top: 55px;
}

.fixed {
  position: fixed;
  width: 50px;
  height: 50px;
  background-color: rgb(43, 144, 234);
  border-radius: 50%;
  right: 50px;
  bottom: 50px;
  box-shadow: 0px 0px 100px 5PX black;
  cursor: pointer;
}
  
  </style>
  