<template>
    <b-container>
        <h1><b>Respiratory Disease Information</b></h1>
        <h2>Our Mission: To provide healthcare resources and informations for individuals who are experiencing symptoms of respiratory disease.</h2>
        <b-row align-v="center">
            <b-col>
                <b-card
                    title="Map Clustering"
                    img-src="https://picsum.photos/600/300/?image=25"
                    tag="article"
                    style="max-width:20rem"
                    class="mb-2"
                >
                    <b-card-text>
                        Compiled Twitter Data That Shows Trend in Respiratory Disease Symptoms by Severity.
                    </b-card-text>
                    <b-button variant="primary"><router-link class="routerlink" to="/map">Map</router-link></b-button>
                </b-card>
            </b-col>
            <b-col>
                <b-card
                    title="Education Resources"
                    img-src="https://picsum.photos/600/300/?image=25"
                    tag="article"
                    style="max-width:20rem"
                    class="mb-2"
                >
                    <b-card-text>
                        Knowledge about Flu/Cold Symptoms and More Severe Respiratory Systems such as Pneumonia and Bronchitis.
                    </b-card-text>
                    <b-button variant="primary"><router-link class="routerlink" to="/map">Education</router-link></b-button>
                </b-card>
            </b-col>
            <b-col>
                <b-card
                    title="Physical Resources"
                    img-src="https://picsum.photos/600/300/?image=25"
                    tag="article"
                    style="max-width:20rem"
                    class="mb-2"
                >
                    <b-card-text>
                        Nearest hospital, pharmacy, or vaccine clinic by country and state
                    </b-card-text>
                    <b-button variant="primary"><router-link class="routerlink" to="/resources">Resources</router-link></b-button>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'HomeComponent',
  props: {},
  created() {
    document.title = "Twitter NLP Visualization";
  },
  data() {
    return {

    }
  },
}
</script>

<style>
/* Your component styles */

body {
  background-color: #4A6572;
}

h1 {
    margin-top:50px;
    margin-bottom:50px;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}
h2 {
    margin-bottom:50px;
}

.routerlink {
    color:white;
}
</style>
